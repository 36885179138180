import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import typy from 'typy'

import Layout from '../components/Layout'

const essayQuery = graphql`
  query {
    content(uid: {eq: "c1"}) {
      items {
        header
        content
      }
    }
  }
`

const Terms = () => {
  const { content } = useStaticQuery(essayQuery)
  const contentItems = typy(content, 'items').safeArray
  return (
    <Layout>
      <section>
        {contentItems.map(item => {
          return (
            <section>
              <h1>{item.header}</h1>
              <div dangerouslySetInnerHTML={{ __html: item.content }} />
            </section>
          )
        })}
      </section>
    </Layout>
  )
}

export default Terms
